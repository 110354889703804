import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './PrivacyPolicy.module.css';

const PrivacyPolicy = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <p className={css.lastUpdated}>Last updated: February 2022</p>

      <div>
        <p>Data privacy is an important concern to us. Therefore, in this Privacy Policy, you will learn what data is used and how, when you decide to use our Host4Ukraine.com website (subsequently referred to as the "Website" or "Host4Ukraine.com"). In particular, we would like to explain to you how we ensure the protection of your personal data.</p>
        <p>&nbsp;</p>
        <p><strong>Responsible</strong></p>
        <p>Host4Ukraine is an initiative of the Churchpool GmbH. Your contact for the Website and also operator of the platform Host4Ukraine.com ("we", "us") is the</p>
        <p>&nbsp;</p>
        <p>Churchpool GmbH</p>
        <p>Überseetor 20</p>
        <p>28217 Bremen</p>
        <p>&nbsp;</p>
        <p>We are also the so-called controller within the meaning of the General Data Protection Regulation (GDPR) for the processing of your personal data when you use Host4Ukraine.com. If you have any questions or concerns about this, our support team will be happy to assist you by email at support@Host4Ukraine.com.</p>
        <p>&nbsp;</p>
        <p><strong>Contact details of the data protection officer</strong></p>
        <p>Our external data protection officer will also be happy to provide you with information on the subject of data protection under the following contact details:</p>
        <p>&nbsp;</p>
        <p>datenschutz nord GmbH</p>
        <p>Konsul-Smidt-Strasse 88</p>
        <p>28217 Bremen</p>
        <p>Web: www.datenschutz-nord-gruppe.de</p>
        <p>E-mail: office@datenschutz-nord.de</p>
        <p>&nbsp;</p>
        <p>If you contact our data protection officer, please refer to Host4Ukraine.com.</p>
        <p>&nbsp;</p>
        <p><strong>Usage data</strong></p>
        <p>When you use our website or the app, so-called usage data is temporarily transmitted on our servers for technical and statistical purposes as well as for the detection and correction of errors. This is necessary for the website to function properly and to improve the quality of Host4Ukraine.com. This data set consists of i.a.:</p>
        <ul style={{listStyleType: 'disc'}}>
          <li>Name and address of the requested content</li>
          <li>Date and time of the request,</li>
          <li>Amount of data transferred,</li>
          <li>Access status (content transferred, content not found),</li>
          <li>Information about the web browser used and the operating system or type and version of your mobile device (e.g. "iPhone 6, iOS 8.1")</li>
          <li>If applicable, the referral link indicating from which page you reached ours,</li>
          <li>IP address of the requesting device</li>
        </ul>
        <p>&nbsp;</p>
        <p><strong>Data security</strong></p>
        <p>In order to protect your data from unwanted access as comprehensively as possible, we take technical and organizational measures. We use an encryption procedure on Host4Ukraine.com. Your data is transferred from your computer to our server and vice versa via the Internet using Transport Layer Security (TLS) encryption.</p>
        <p>&nbsp;</p>
        <p><strong>Required cookies</strong></p>
        <p>We use cookies, which are necessary for the use of our website. Cookies are small text files that are stored on your terminal device and can be read. A distinction is made between session cookies, which are deleted as soon as you close your browser, and permanent cookies, which are stored beyond the individual session.</p>
        <p>&nbsp;</p>
        <p>In some cases, these cookies only contain information about certain settings and are not personally identifiable. They may also be necessary to enable user guidance, security and implementation of the site.</p>
        <p>&nbsp;</p>
        <p>We use these cookies on the basis of Art. 6 para. 1 p. 1 lit. f GDPR.</p>
        <p>&nbsp;</p>
        <p>For more information see our cookie policy: https://www.websitepolicies.com/policies/view/464geoa8</p>
        <p>&nbsp;</p>
        <p>In the case of the website, you can set your browser to inform you about the placement of cookies. This makes the use of cookies transparent. You can also delete cookies at any time via the appropriate browser setting and prevent the setting of new cookies. Please note that the website may then not be displayed correctly and some functions may no longer be technically available.</p>
        <p>&nbsp;</p>
        <p><strong>Map services</strong></p>
        <p>In our app, we embed map services that are not stored on our servers. Calling up our pages with embedded map services leads to content being reloaded from the third-party provider that provides the map services. This provides the third-party provider with the information that you have accessed our site as well as the usage data technically required in this context.</p>
        <p>We have no influence on the further data processing by the third-party provider.</p>
        <p>The embedding is based on Art. 6 para. 1 p. 1 lit. f DSGVO and in the interest of enabling you to use map services.</p>
        <p>&nbsp;</p>
        <p>Google Ireland Ltd.</p>
        <p>Adequate level of data protection: EU standard contract</p>
        <p>Option to object: If you wish to object to embedding, please stop using our platform.</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p><strong>Other order processors</strong></p>
        <p>We pass on your data to service providers as part of order processing pursuant to Art. 28 GDPR, which support us in the operation of Host4Ukraine.com and the associated processes. These are, for example, hosting service providers. Our service providers are strictly bound by instructions to us and are contractually obligated accordingly.</p>
        <p>&nbsp;</p>
        <p><strong>Registration</strong></p>
        <p>If you decide to register with Host4Ukraine.com, you will be asked to provide us with the following personal data: Name and e-mail. Without these data a registration is not possible and therefore the use of Host4Ukraine.com is limited. In connection with the registration data of the applicant or user, we also archive the date and time of registration. The legal basis for the processing is Art. 6 para. 1 p. 1 lit. b DSGVO.</p>
        <p>&nbsp;</p>
        <p><strong>Profile data</strong></p>
        <p>Furthermore, we store all personal data that you create on a voluntary basis as part of your profile (e.g. profile photo and name). The legal basis for the processing of profile data is Art. 6 para. 1 s.1 lit. a DSGVO.</p>
        <p>&nbsp;</p>
        <p><strong>Content data</strong></p>
        <p>The content you deposit and provide when using Host4Ukraine.com (e.g. pictures, address and contact data, description etc.) is also stored by us. The legal basis for the processing of the content data is Art. 6 para. 1 s.1 lit. a DSGVO.</p>
        <p>&nbsp;</p>
        <p><strong>E-mail notifications</strong></p>
        <p>We notify you of certain news on Host4Ukraine via e-mail, even if you are not actively using the website at the time. The legal basis is Art. 6 para. 1 p. 1 lit. a GDPR.</p>
        <p>&nbsp;</p>
        <p><strong>Storage period of data</strong></p>
        <p>In principle, we only store personal data for as long as is necessary to fulfill contractual or legal obligations or to enforce civil claims.</p>
        <p>&nbsp;</p>
        <p>Registration data and profile data are stored for up to four weeks after the deletion of your user account and then deleted. Content data will be stored until your user account is deleted and then anonymized.</p>
        <p><strong>&nbsp;</strong></p>
        <p><strong>Your rights as a data subject</strong></p>
        <p>When processing your personal data, the GDPR grants you certain rights as a data subject:</p>
        <p>&nbsp;</p>
        <p>Right to information (Art. 15 GDPR).</p>
        <p>You have the right to request confirmation as to whether personal data concerning you is being processed; if this is the case, you have a right to information about this personal data and to the information listed in detail in Art. 15 GDPR.</p>
        <p>&nbsp;</p>
        <p>Right to rectification (Art. 16 GDPR).</p>
        <p>You have the right to request without undue delay the rectification of any inaccurate personal data concerning you and, where applicable, the completion of any incomplete data.</p>
        <p>&nbsp;</p>
        <p>Right to erasure (Art. 17 GDPR)</p>
        <p>You have the right to request that personal data concerning you be erased without delay, provided that one of the reasons listed in detail in Art. 17 GDPR applies.</p>
        <p>&nbsp;</p>
        <p>Right to restriction of processing (Art. 18 GDPR)</p>
        <p>You have the right to request the restriction of processing if one of the conditions listed in Art. 18 GDPR applies, e.g. if you have objected to the processing, for the duration of the review by the controller.</p>
        <p>&nbsp;</p>
        <p>Right to data portability (Art. 20 GDPR).</p>
        <p>In certain cases, which are listed in detail in Art. 20 GDPR, you have the right to receive the personal data concerning you in a structured, common and machine-readable format or to request the transfer of this data to a third party.</p>
        <p>&nbsp;</p>
        <p>Right of withdrawal (Art. 7 GDPR).</p>
        <p>If the processing of data is based on your consent, you are entitled to revoke your consent to the use of your personal data at any time in accordance with Art. 7 (3) GDPR. Please note that the revocation is only effective for the future. Processing that took place before the revocation is not affected.</p>
        <p>&nbsp;</p>
        <p>Right of objection (Art. 21 GDPR)</p>
        <p>If data is collected on the basis of Art. 6 para. 1 sentence 1 lit. f GDPR (data processing for the protection of legitimate interests) or on the basis of Art. 6 para. 1 sentence 1 lit. e GDPR (data processing for the protection of public interest or in the exercise of official authority), you have the right to object to the processing at any time for reasons arising from your particular situation. We will then no longer process the personal data unless there are demonstrably compelling legitimate grounds for the processing which override your interests, rights and freedoms, or the processing serves to assert, exercise or defend legal claims.</p>
        <p>&nbsp;</p>
        <p>Right to lodge a complaint with a supervisory authority (Art. 77 GDPR).</p>
        <p>Pursuant to Art. 77 GDPR, you have the right to lodge a complaint with a supervisory authority if you are of the opinion that the processing of the data concerning you violates data protection provisions. In particular, the right of complaint may be asserted before a supervisory authority in the Member State of your habitual residence, your place of work or the place of the alleged infringement.</p>
        <p>&nbsp;</p>
        <p>Assertion of your rights</p>
        <p>To assert your data subject rights, please contact Churchpool GmbH, Überseetor 20, 28217 Bremen, Germany.</p>
      </div>
    </div>
  );
};

PrivacyPolicy.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

PrivacyPolicy.propTypes = {
  rootClassName: string,
  className: string,
};

export default PrivacyPolicy;
