/*
 * Marketplace specific configuration.
 *
 * Every filter needs to have following keys:
 * - id:     Unique id of the filter.
 * - label:  The default label of the filter.
 * - type:   String that represents one of the existing filter components:
 *           BookingDateRangeFilter, KeywordFilter, PriceFilter,
 *           SelectSingleFilter, SelectMultipleFilter.
 * - group:  Is this 'primary' or 'secondary' filter?
 *           Primary filters are visible on desktop layout by default.
 *           Secondary filters are behind "More filters" button.
 *           Read more from src/containers/SearchPage/README.md
 * - queryParamNames: Describes parameters to be used with queries
 *                    (e.g. 'price' or 'pub_amenities'). Most of these are
 *                    the same between webapp URLs and API query params.
 *                    You can't change 'dates', 'price', or 'keywords'
 *                    since those filters are fixed to a specific attribute.
 * - config: Extra configuration that the filter component needs.
 *
 * Note 1: Labels could be tied to translation file
 *         by importing FormattedMessage:
 *         <FormattedMessage id="some.translation.key.here" />
 *
 * Note 2: If you need to add new custom filter components,
 *         you need to take those into use in:
 *         src/containers/SearchPage/FilterComponent.js
 *
 * Note 3: If you just want to create more enum filters
 *         (i.e. SelectSingleFilter, SelectMultipleFilter),
 *         you can just add more configurations with those filter types
 *         and tie them with correct extended data key
 *         (i.e. pub_<key> or meta_<key>).
 */

export const filters = [
  // {
  //   id: 'dates',
  //   label: 'Dates',
  //   type: 'BookingDateRangeFilter',
  //   group: 'primary',
  //   // Note: BookingDateRangeFilter is fixed filter,
  //   // you can't change "queryParamNames: ['dates'],"
  //   queryParamNames: ['dates'],
  //   config: {},
  // },
  {
    id: 'isVerified',
    label: 'Verified',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_isVerified'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'verified', label: 'Verified user' },
      ],
    },
  },
  {
    id: 'keyword',
    label: 'Keyword',
    type: 'KeywordFilter',
    group: 'primary',
    // Note: KeywordFilter is fixed filter,
    // you can't change "queryParamNames: ['keywords'],"
    queryParamNames: ['keywords'],
    // NOTE: If you are ordering search results by distance
    // the keyword search can't be used at the same time.
    // You can turn on/off ordering by distance from config.js file.
    config: {},
  },
  {
    id: 'profileType',
    label: 'Profile type',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_profileType'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'organisation', label: 'Organisation' },
        { key: 'private', label: 'Private' },
      ],
    },
  },
  {
    id: 'organisationType',
    label: 'Organisation type',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_organisationType'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'NGO', label: 'NGO' },
        { key: 'church', label: 'Church' },
        { key: 'corporation', label: 'Corporation' },
        { key: 'other', label: 'Other' },
      ],
    },
  },
  {
    id: 'category',
    label: 'Listing type',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_category'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'lookingForPlace', label: 'Looking for' },
        { key: 'offeringPlace', label: 'Offering' },
      ],
    },
  },
  {
    id: 'subcategories',
    label: 'Category',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_subcategories'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'accomodation', label: 'Accommodation' },
        { key: 'supplies', label: 'Supplies' },
        { key: 'services', label: 'Services' },
        { key: 'donations', label: 'Donations' },
        { key: 'jobs', label: 'Jobs' },
      ],
    },
  },

  {
    id: 'capacityNumberOfPeople',
    label: 'Capacity',
    type: 'PriceFilter',
    group: 'primary',
    // Note: PriceFilter is fixed filter,
    // you can't change "queryParamNames: ['price'],"
    queryParamNames: ['pub_capacityNumberOfPeople'],
    // Price filter configuration
    // Note: unlike most prices this is not handled in subunits
    config: {
      min: 0,
      max: 30,
      step: 1,
    },
  },
  // {
  //   id: 'capacity',
  //   label: 'Capacity',
  //   type: 'SelectSingleFilter',
  //   group: 'primary',
  //   queryParamNames: ['pub_capacity'],
  //   config: {
  //     // "key" is the option you see in Flex Console.
  //     // "label" is set here for the UI only.
  //     // Note: label is not added through the translation files
  //     // to make filter customizations a bit easier.
  //     options: [
  //       { key: '1', label: '1 person' },
  //       { key: '2', label: '2 persons' },
  //       { key: '3', label: '3 persons' },
  //       { key: '4', label: '4 persons' },
  //       { key: '5', label: '5 persons' },
  //       { key: '6', label: '6 persons' },
  //       { key: '7', label: '7 persons' },
  //       { key: '8', label: '8 persons' },
  //       { key: '9', label: '9 persons' },
  //       { key: '10', label: '10 persons' },

  //       //{ key: 'wood', label: 'Wood' },
  //       //{ key: 'other', label: 'Other' },
  //     ],
  //   },
  // },
  {
    id: 'subcategoriesLevelOne',
    label: 'Subcategories',
    type: 'SelectMultipleFilter', //'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_subcategoriesLevelOne'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_any',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        //supplies
        { key: 'food', label: 'Food' },
        { key: 'clothes', label: 'Clothes' },
        { key: 'cosmetics', label: 'Toiletries' },
        { key: 'medical', label: 'Medical' },
        //services
        { key: 'counselling', label: 'Counselling' },
        { key: 'legalAdvice', label: 'Legal advice' },
        { key: 'translation', label: 'Translation' },
        { key: 'transport', label: 'Transport' },

        //common option for supplies and services
        { key: 'other', label: 'Other' },
      ],
    },
  },
];

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // Keyword filter is sorting the results already by relevance.
  // If keyword filter is active, we need to disable sorting.
  conflictingFilters: ['keyword'],

  options: [
    { key: 'createdAt', label: 'Newest' },
    { key: '-createdAt', label: 'Oldest' },
    // { key: '-price', label: 'Lowest price' },
    // { key: 'price', label: 'Highest price' },

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    { key: 'relevance', label: 'Relevance', longLabel: 'Relevance (Keyword search)' },
  ],
};
