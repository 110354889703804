import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './TermsOfService.module.css';

const TermsOfService = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <p className={css.lastUpdated}>Last updated: February 2022</p>

      <div>
        <p>Welcome to Host4Ukrainee.com (hereinafter: "Host4Ukraine")! The following Terms of Use govern the use of "Host4Ukraine" by registered users (hereinafter: "Membership") via the Internet, mobile services, apps, other domains or communication channels.</p>
        <p>&nbsp;</p>
        <p>"Host4Ukraine" is an initiative of Churchpool GmbH. Hence, your contact and contractual partner for "Host4Ukraine" is Churchpool GmbH, hereinafter also referred to as "we".</p>
        <p>&nbsp;</p>
        <p><strong>1 Login and registration, user account</strong></p>
        <p>1.1 Membership is established by successfully fulfilling the sign-up form. A claim to membership does not exist.</p>
        <p>&nbsp;</p>
        <p>1.2 You must be at least 16 years old to register. For more information, please refer to our Privacy Policy.</p>
        <p>&nbsp;</p>
        <p>1.3 When registering, you must provide true, current and complete information. Your data, especially your name and email, must always be kept up to date in your member account. Multiple registrations under different member names are prohibited; re-registration when we have cancelled a membership and prohibited re-registration is also prohibited.</p>
        <p>&nbsp;</p>
        <p>1.4 The membership is personal and only you are allowed to use your access data, not e.g. family members or friends. You must keep the access data to your user account secret. Please inform us immediately if you notice any misuse.</p>
        <p>&nbsp;</p>
        <p><strong>2. Duration and cancellation</strong></p>
        <p>2.1 The membership runs for an indefinite period. It can be terminated by you at any time with immediate effect. In case of an ordinary termination by us, the notice period is two weeks.</p>
        <p>&nbsp;</p>
        <p>2.2 The statutory right to terminate for good cause remains unaffected. In the event of termination without notice, we shall be entitled to make you inactive or even delete your account.</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p><strong>3 Use of "Host4Ukraine</strong></p>
        <p>3.1 When using "Host4Ukraine" you undertake to observe the applicable statutory provisions. This concerns e.g. the protection of the personal rights of other users or the copyright or existing rights of use of photos or other works.</p>
        <p>&nbsp;</p>
        <p>3.2 "Host4Ukraine" is free of charge. We do not guarantee or warrant any information on "Host4Ukraine", any content of other users or any particular availability or functionality of "Host4Ukraine".</p>
        <p>&nbsp;</p>
        <p>3.3 We do not pre-screen user content stored on "Host4Ukraine". However, we reserve the right to block or delete both users and their content at any time. We would do this in particular if we become aware of a violation of applicable law <span style={{color: 'black'}}>or of our terms of use.&nbsp;</span>We expressly reserve the right to delete and / or block for other reasons. These reasons are at our discretion. We are not obligated to inform users about these reasons.</p>
        <p>&nbsp;</p>
        <p>3.4 You may not use your private profile at "Host4Ukraine" for business purposes, e.g. for the commercial offer of goods or services or advertising.</p>
        <p>&nbsp;</p>
        <p>3.5 You remain the owner of your content (e.g. profile photos published by you on "Host4Ukraine" ), but you grant Host4Ukraine the full rights necessary for the objective use, i.e. you grant us a non-exclusive, transferable, sublicensable and temporally and spatially unrestricted license to host, use, distribute, modify, perform, copy, publicly perform or display, translate and create derivative works from your content. This license is for the sole purpose of making the applicable Content available on Host4Ukraine.</p>
        <p>&nbsp;</p>
        <p>3.6 We retain or acquire the exclusive and unrestricted rights to the structure and functionalities of "Host4Ukraine" as well as their designations and the databases of "Host4Ukraine" in the event of further development.</p>
        <p>&nbsp;</p>
        <p><strong>4 Amendment of the Terms of Use</strong></p>
        <p>Host4Ukraine is entitled to change these Terms of Use with effect for future use upon notice to you. The change will be deemed approved if you have not deleted your Account within 14 days after notification of the change.</p>
        <p>&nbsp;</p>
        <p><strong>5. Data protection</strong></p>
        <p>"Host4Ukraine" is subject to German and European data protection law. Information on the collection, processing and use of personal data can be found in our privacy policy on our website at https://www.Host4Ukrainee.com/en/infos/privacy.</p>
        <p>&nbsp;</p>
        <p><strong>6. Limitation of liability, exclusion of withdrawal in case of certain breaches of duty</strong></p>
        <p>6.1 Host4Ukraine shall be liable without limitation under the German Product Liability Act, for damages caused intentionally or by gross negligence, in case of fraudulent concealment of defects, as well as for damages resulting from injury to life, body or health. Liability for damages arising from the breach of a warranty shall also be unlimited.</p>
        <p>&nbsp;</p>
        <p>6.2 In the event of a breach of material contractual obligations (so-called cardinal obligations) based only on simple negligence, Host4Ukraine's liability shall be limited to compensation for the foreseeable contract-typical damage.</p>
        <p><span style={{background: 'yellow'}}>&nbsp;</span></p>
        <p>6.3 Except in the cases referred to in paragraphs 1 and 2, Host4Ukraine shall not be liable for damage caused by simple negligence.</p>
        <p>&nbsp;</p>
        <p>6.4 To the extent that Host4Ukraine's liability is excluded or limited pursuant to the foregoing paragraphs, this shall also apply to the liability of its vicarious agents and assistants.</p>
        <p>&nbsp;</p>
        <p><strong>7. Final provisions</strong></p>
        <p>7.1 Should any provision of these Terms of Use become invalid in whole or in part, the validity of the remaining provisions shall not be affected thereby. The invalid provision must then be replaced by a feasible provision that comes as close as possible to the economic purpose of the invalid provision. The same shall apply in the event that the Terms of Use contain loopholes.</p>
        <p>&nbsp;</p>
        <p>7.2 German law shall apply.</p>
        <p>&nbsp;</p>
        <p>7.3 The place of jurisdiction is Bremen, Germany.</p>
        <p>&nbsp;</p>
        <p>7.4 The European Commission will provide a platform for online dispute resolution here as of 15.02.2016. The email address of Host4Ukraine is support@Host4Ukraine.com. Host4Ukraine is not willing or obliged to participate in dispute resolution proceedings before a consumer arbitration board.</p>
      </div>

    </div>
  );
};

TermsOfService.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

TermsOfService.propTypes = {
  rootClassName: string,
  className: string,
};

export default TermsOfService;
