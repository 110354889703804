import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { NamedLink } from '../../components';

import css from './ModalMissingInformation.module.css';

const VerifyAccountReminder = props => {
  const { className } = props;

  return (
    <div className={className}>
      <p className={css.modalTitle}>
        <FormattedMessage id="ModalMissingInformation.missingVerifyAccountTitle" />
      </p>
      <p className={css.modalMessage}>
        <FormattedMessage id="ModalMissingInformation.missingVerifyAccountText" />
      </p>
      <div className={css.bottomWrapper}>
        <NamedLink className={css.reminderModalLinkButton} name="VerifyPage">
          <FormattedMessage id="ModalMissingInformation.gotoVerify" />
        </NamedLink>
      </div>
    </div>
  );
};

export default VerifyAccountReminder;
