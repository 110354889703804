import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { compose } from 'redux';
import { maxLength, required, composeValidators } from '../../util/validators';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { findOptionsForSelectFilter } from '../../util/search';
import { propTypes } from '../../util/types';
import config from '../../config';
import { Button, FieldCheckboxGroup, Form, FieldSelect  } from '../../components';

import css from './EditListingFeaturesForm.module.css';

const EditListingFeaturesFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        disabled,
        ready,
        rootClassName,
        className,
        name,
        handleSubmit,
        pristine,
        intl,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        filterConfig,
        values
      } = formRenderProps;

      const classes = classNames(rootClassName || css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = disabled || submitInProgress;

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingFeaturesForm.updateFailed" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingFeaturesForm.showListingFailed" />
        </p>
      ) : null;

      const categoryLabel = intl.formatMessage({
        id: 'EditListingDescriptionForm.categoryLabel',
      });

      const categoryPlaceholder = intl.formatMessage({
        id: 'EditListingFeaturesForm.categoryPlaceholder',
      });


      const categoryRequired = required(
        intl.formatMessage({
          id: 'EditListingDescriptionForm.categoryRequired',
        })
      );


      const subCategoryLabel = intl.formatMessage({
        id: 'EditListingDescriptionForm.subCategoryLabel',
      });

      const subCategoryPlaceholder = intl.formatMessage({
        id: 'EditListingFeaturesForm.categoryPlaceholder',
      });


      const subCategoryRequired = required(
        intl.formatMessage({
          id: 'EditListingDescriptionForm.categoryRequired',
        })
      );


      const categories = findOptionsForSelectFilter('category', filterConfig);
      const subcategories = findOptionsForSelectFilter('subcategories', filterConfig);


      let options = findOptionsForSelectFilter('subcategoriesLevelOne', filterConfig);

      const subcategory = values.subcategories;

      const showSubcategoriesLevelOne = ['supplies', 'services'].includes(subcategory);

      if(subcategory === 'supplies'){
        options = options.filter(o => {
          return o.key === 'food' || 
                 o.key === 'clothes' || 
                 o.key === 'cosmetics' ||
                 o.key === 'medical' ||
                 o.key === 'other'
        })
      }


      if(subcategory === 'services'){
        options = options.filter(o => {
          return o.key === 'counselling' || 
                 o.key === 'legalAdvice' || 
                 o.key === 'translation' ||
                 o.key === 'transport' ||
                 o.key === 'other'
        })
      }

     
      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}
          {errorMessageShowListing}

          <FieldSelect
            className={css.category}
            name={'category'}
            id={'category'}
            label={categoryLabel}
            validate={categoryRequired}
          >
            <option disabled hidden value="">
              {categoryPlaceholder}
            </option>
            {categories.map(c => (
              <option key={c.key} value={c.key}>
                {c.label}
              </option>
            ))}
          </FieldSelect>




          <FieldSelect
            className={css.category}
            name={'subcategories'}
            id={'subcategories'}
            label={subCategoryLabel}
            validate={subCategoryRequired}
          >
            <option disabled hidden value="">
              {subCategoryPlaceholder}
            </option>
            {subcategories.map(c => (
              <option key={c.key} value={c.key}>
                {c.label}
              </option>
            ))}
          </FieldSelect>


          {
            showSubcategoriesLevelOne ?
            <FieldCheckboxGroup 
            className={css.features} 
            id={'subcategoriesLevelOne'} 
            name={'subcategoriesLevelOne'} 
            options={options}
            radio={true}
            />
            :
            null
          }


          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingFeaturesFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  fetchErrors: null,
  filterConfig: config.custom.filters,
};

EditListingFeaturesFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
  name: string.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  filterConfig: propTypes.filterConfig,
};

const EditListingFeaturesForm = EditListingFeaturesFormComponent;

export default compose(injectIntl)(EditListingFeaturesForm);
